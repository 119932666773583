<script setup lang="ts">
import { mixed, object, string, number } from 'yup'

enum ImageBlockVariant {
  Default = 'default',
  FullWidth = 'full-width',
  LeftAligned = 'left-aligned',
}

const { t } = useI18n()

// Schema definition
const imageSchema = object({
  // TODO: image size validation, like in vacancy schema.
  // TODO: file size, make a reusable file schema that can be used for the vacancy form as well
  image: mixed<File | ContentFile>().required(),
  altText: string().required(),
  caption: string().optional(),
  variant: string().oneOf(Object.values(ImageBlockVariant)).default(ImageBlockVariant.Default),
  maxWidth: number().optional(),
})

// Inject the block form schema
useBlockFormSchema(imageSchema)
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <FormImageField
      name="image"
      :label="t('image')"
    />
    <FormListField
      name="variant"
      :label="t('variant.label')"
      :items="Object.values(ImageBlockVariant)"
      :label-factory="(variant) => t('variant.options.' + variant)"
    />
    <FormField
      name="altText"
      :label="t('altText.label')"
      :placeholder="t('altText.placeholder')"
    />
    <FormField
      name="caption"
      :label="t('caption.label')"
      :placeholder="t('caption.placeholder')"
    />
    <FormField
      name="maxWidth"
      :label="t('maxWidth.label')"
      :placeholder="t('maxWidth.placeholder')"
    />
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "image": "Image",
    "altText": {
      "label": "Image description (alt text for Google)",
      "placeholder": "E.g. Photo of volunteer"
    },
    "caption": {
      "label": "Caption (optional)",
      "placeholder": "E.g. Volunteer in action!"
    },
    "variant": {
      "label": "Variant",
      "placeholder": "Default, Full width, Left aligned",
      "options": {
        "default": "Default (centered)",
        "full-width": "Full width",
        "left-aligned": "Left aligned"
      }
    },
    "maxWidth": {
      "label": "Max width (in pixels)",
      "placeholder": "E.g. 500"
    }
  },
  "nl": {
    "image": "Afbeelding",
    "altText": {
      "label": "Beschrijving van afbeelding (alt tekst voor Google)",
      "placeholder": "Bijv: Foto van vrijwilliger"
    },
    "caption": {
      "label": "Onderschrift (optioneel)",
      "placeholder": "Bijv: vrijwilliger in actie!"
    },
    "variant": {
      "label": "Stijlvariant",
      "placeholder": "Standaard, Volledige breedte, Links uitgelijnd",
      "options": {
        "default": "Standaard (gecentreerd)",
        "full-width": "Volledige breedte",
        "left-aligned": "Links uitgelijnd"
      }
    },
    "maxWidth": {
      "label": "Maximale breedte (in pixels)",
      "placeholder": "Bijv: 500"
    }
  }
}
</i18n>
