<script setup lang="ts">
// @roos @maud
// wat moet ik hier met tekstkleur en stijl doen? Het is voor Mas4you en Oldebroek precies anders.

import type { QuoteItem } from '#imports'
import { getContentFileUrl } from '#imports'

const { quotes = [], textColor = 'white' } = defineProps<{
  quotes: QuoteItem[]
  textColor?: 'white' | 'primary' | 'text-dark'
}>()

// Track current quote index
const currentQuoteIndex = ref(0)
const hasMultipleQuotes = computed(() => quotes.length > 1)

// Navigation methods
const showNextQuote = () => {
  if (currentQuoteIndex.value < quotes.length - 1) {
    currentQuoteIndex.value++
  }
}

const showPreviousQuote = () => {
  if (currentQuoteIndex.value > 0) {
    currentQuoteIndex.value--
  }
}

const canShowPrevious = computed(() => currentQuoteIndex.value > 0)
const canShowNext = computed(() => currentQuoteIndex.value < quotes.length - 1)

// Background color
const bgColorClass = computed(() => textColor === 'primary' ? 'bg-primary-500 dark:bg-primary-600' : 'bg-white dark:bg-muted-900')

// Text colors
const oldebroekTextClass = 'color-[var(--color-theme-content-text)] dark:text-white'
const quoteColorClass = oldebroekTextClass
const authorColorClass = oldebroekTextClass
const jobTitleColorClass = oldebroekTextClass

// @roos TODO fix styling for all themes
// const quoteColorClass = computed(() => textColor === 'primary' ? 'text-white dark:text-white' : 'text-secondary-500 dark:text-white')
// const authorColorClass = computed(() => textColor === 'primary' ? 'text-white dark:text-white' : 'text-black dark:text-white')
// const jobTitleColorClass = computed(() => textColor === 'primary' ? 'text-white/50 dark:text-white/50' : 'text-gray-600 dark:text-white/50')

const { cardRadiusClass } = useThemeClasses()

</script>

<template>
  
  <div
    v-if="quotes.length > 0"
    :class="[bgColorClass, cardRadiusClass]"
    class="flex flex-col items-center justify-center p-8"
  >
    
    <p :class="[quoteColorClass]" class="mb-6 p-4 text-center text-2xl font-bold">
      “{{ quotes[currentQuoteIndex].text }}”
    </p>

    <div class="flex items-center gap-4">
      <img
        v-if="getContentFileUrl(quotes[currentQuoteIndex]?.photo)"
        :src="getContentFileUrl(quotes[currentQuoteIndex]?.photo)"
        :alt="quotes[currentQuoteIndex].author"
        class="size-12 rounded-full object-cover"
      >
      <div>
        <p :class="authorColorClass" class="font-semibold">
          {{ quotes[currentQuoteIndex].author }}
        </p>
        <p
          v-if="quotes[currentQuoteIndex].jobTitle"
          :class="jobTitleColorClass"
          class="text-sm"
        >
          {{ quotes[currentQuoteIndex].jobTitle }}
        </p>
      </div>
    </div>

    
    <div v-if="hasMultipleQuotes" class="mt-6 flex justify-center gap-4">
      <BaseButtonIcon
        :disabled="!canShowPrevious"
        color="primary"
        shape="curved"
        @click="showPreviousQuote"
      >
        <Icon name="ph:arrow-left" />
      </BaseButtonIcon>
      <BaseButtonIcon
        :disabled="!canShowNext"
        color="primary"
        shape="curved"
        @click="showNextQuote"
      >
        <Icon name="ph:arrow-right" />
      </BaseButtonIcon>
    </div>
  </div>
</template>
