import { array, mixed, object, string, type InferType } from 'yup'

export interface QuoteItem {
  text: string
  author: string
  jobTitle?: string
  photo?: File | ContentFile
}

export const quoteSchema = object({
  text: string().required(),
  author: string().required(),
  jobTitle: string().optional(),
  photo: mixed<File | ContentFile>().optional(),
})

export const quoteBlockSchema = object({
  // The title of the block
  title: string().optional(),
  // The main photo on the left
  image: mixed<File | ContentFile>().optional(),
  // Array of quotes to show on the right
  quotes: array(quoteSchema).min(1).required(),
})

// Schema payload of the block
export interface QuoteBlockPayload extends InferType<typeof quoteBlockSchema> {}
// Schema payload of a single quote
export type QuotePayload = InferType<typeof quoteSchema>
